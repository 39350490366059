<template>
    <el-container class="content">
      <el-header class="header">
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        <div class="center">
          面诊订单详情
          <div v-if="pageName==='待支付'" class="text text1">{{pageName}}</div>
          <div v-if="pageName==='服务中'" class="text text2">{{pageName}}</div>
          <div v-if="pageName==='已完成'" class="text text3">{{pageName}}</div>
        </div>
        <div class="right">
          <!-- <template v-if="pageName==='已预约'||pageName==='服务中'">
            <div v-if="pageName==='已预约'" class="r-box" @click="jumpAbout('修改')">修改</div>
            <div class="r-box" @click="closeOrder">关闭</div>
            <div class="r-box" @click="logOrder">打印</div>
            <div class="r-box" @click="markShow=true">备注</div>
          </template>
          <template v-if="pageName==='已关闭'">
            <div class="r-box" @click="delOrder">删除</div>
            <div class="r-box" @click="markShow=true">备注</div>
          </template> -->
        </div>
      </el-header>
      <el-main class="main">
        <div class="info">
          <div class="title">
            顾客信息
          </div>
          <div class="list-box">
            <div class="list">
              <div class="l-left">
                顾客姓名
              </div>
              <div class="l-right">
                {{ user.realname }}<span>{{ user.dengji }}</span>
              </div>
            </div>
            <div class="list">
              <div class="l-left">
                手机号码
              </div>
              <div class="l-right">
                {{ user.mobile }}
              </div>
            </div>
            <div class="list">
              <div class="l-left">
                <span >开始时间</span>
                <!-- <span v-if="pageName==='服务中'">到店时间</span>
                <span v-if="pageName==='已关闭'">关闭时间</span> -->
              </div>
              <div class="l-right" v-if="Info.createtime">
                {{ Info.createtime | timeform(true) }}
              </div>
            </div>
            <div class="list">
                <div class="l-left">
                  是否过敏
                </div>
                <div class="l-right">
                  {{ Info.allergy }}
                </div>
              </div>
            </div>
  
        </div>
        <div class="info">
          <div class="title">
            服务项目
          </div>
          <div class="service-box">
            <div class="service-list" v-for="(item,i) in shop" :key="i">
              <div class="s-l">
                <div class="name">{{ item.shopname }}</div>
                <div class="time">{{ item.sid }}</div>
              </div>
              <div class="s-c">
                x{{ item.num }}
              </div>
              <div class="s-c">
                ¥ {{ item.unit_price }}
              </div>
              <div class="s-r">
                <el-select
                    :disabled="item.type == 2 || pageName!='待支付'"
                    v-model="item.manystoreperson_id"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="服务师傅"
                    @change="Selectchange($event,item.id)">
                    <el-option
                    v-for="item in shifuList"
                    :key="item.id"
                    :label="item.realname"
                    :value="item.id"
                    >
                    </el-option>
                </el-select>
                <!-- <div class="avatar">
                  <img src="@/assets/images/avatar.png" alt="">
                </div>
                <div class="name">
                  顾小敏(美容师)
                </div>
                <template>
                  <div v-if="pageName==='已预约'" class="change" @click="serviceChangeShow=true">
                    选择<span class="el-icon-arrow-right"></span>
                  </div>
                  <div v-if="pageName==='服务中'" class="change">
                    <el-tooltip v-if="i!==2" class="item" effect="dark" content="点击提前解除技师占用" placement="top-end">
                      <span class="span span1" @click="removeTakeUp">解除占用</span>
                    </el-tooltip>
  
                    <span class="span span2" v-else>已解除占用</span>
                  </div>
                  <div v-if="pageName==='已关闭'" class="change">
                  </div>
                </template> -->
              </div>
              <div class="s-c">
               {{ item.status }}
               <el-button type="primary" :disabled="item.status != '服务中'" @click="quren(item.id)" v-if="pageName == '服务中'">确认</el-button>
              </div>
            </div>
          </div>
  
        </div>
        <div class="info">
          <div class="info">
            <div class="title">
              诊断内容：
            </div>
            <div class="service-box">
              <el-input
                  type="textarea"
                  disabled
                  :rows="5"
                  placeholder="诊断内容"
                  v-model="Info.diagnosis">
              </el-input>
            </div>
          </div>
          <div class="info">
            <div class="title">
              舌苔照片(前)：
            </div>
            <div class="service-box">
              <el-image
              :preview-src-list="srcList"
              style="width: 200px; height: 200px"
              :src='srcList[0]'
              ></el-image>
            </div>
          </div>
          <div class="info" v-if="pageName != '待支付'">
            <div class="title">
              舌苔照片(后)：
            </div>
            <div class="service-box">
              <el-image
              v-if="housrcList.length != 0"
              :preview-src-list="housrcList"
              style="width: 200px; height: 200px"
              :src='housrcList[0]'
              ></el-image>

              <el-upload
              v-else
              class="avatar-uploader"
              :action="this.$imgFile"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>

            </div>
          </div>
          <div class="title">
            备注信息：
          </div>
          <div class="service-box">
            <el-input
                type="textarea"
                disabled
                :rows="5"
                placeholder="备注信息"
                v-model="Info.remarks">
            </el-input>
            <!-- <div class="service-list" v-for="(item,i) in 3" :key="i">
              <div class="s-l">
                <div class="name">欧诺资弹力素</div>
                <div class="time">500ml</div>
              </div>
              <div class="s-c">
                ¥ 99.00
              </div>
              <div class="s-r">
  
              </div>
            </div> -->
          </div>
        </div>
        <div class="info">
          <div class="title">
            注意事项：
          </div>
          <div class="service-box">
            <el-input
                type="textarea"
                disabled
                :rows="5"
                placeholder="注意事项"
                v-model="Info.take_care">
            </el-input>
          </div>
        </div>
        <div style="width:100%;height:100px;"></div>
        <div class="footer">
          <!-- <div v-if="pageName==='已预约'" class="about">
            <el-button type="primary" round icon="el-icon-check" @click="userArrive">顾客已到店</el-button>
          </div> -->
          <div  class="service" v-if="pageName==='待支付'">
            <div class="left">
              <span>共{{ shop.length }}项</span> <span>合计：<i>¥{{ allmoney }}</i></span> <span></span>
            </div>
            <div class="right">
              <!-- <el-button type="primary" round @click="jumpCaseReg">修改</el-button> -->
              <el-button type="danger" round @click="jumpPaySettle">付款</el-button>
            </div>
          </div>
          <!-- <div v-if="pageName==='已关闭'">
          </div> -->
        </div>
      </el-main>
      <!-- 备注 -->
      <el-dialog class="dialog1" title="单据备注" center :visible.sync="markShow" width="30%" :before-close="handleClose">
        <el-input v-model="markVal" placeholder="请输入单据备注"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="markShow = false">取 消</el-button>
          <el-button type="primary" @click="markShow = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 选择美容师 -->
      <el-dialog class="dialog" title="选择美容师" :visible.sync="serviceChangeShow" width="30%" :before-close="handleClose">
        <template v-if="true">
          <div class="alert el-icon-warning">
            合理的排班会让工作更便捷、高效哦~
          </div>
          <el-container style="height:370px;">
            <el-main class="cell-list">
              <el-radio-group v-model="cellRadio">
                <el-radio :disabled="!i" :label="i" v-for="(item,i) in 20" :key="i" @change="changeTeacher(item)">
                  <div class="disable" v-if="!i">
                    占用
                  </div>
                  <div class="title">
                    <img src="@/assets/images/avatar.png" alt="">
                    顾小敏(美容师)
                  </div>
                </el-radio>
              </el-radio-group>
            </el-main>
          </el-container>
        </template>
        <template v-else>
          <div class="empty-box">
            <div>
              <img src="@/assets/images/empty.png" alt="">
            </div>
            <div class="text">
              您还没有排班哦~
            </div>
            <div>
              <el-button type="primary" @click="jumpScheduling">立即排班</el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </el-container>
  </template>
  
  <script>
    export default {
      data() {
        return {
          imageUrl: '',
          fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
          pageName: '',
          markShow: false,
          markVal: '',
          serviceChangeShow: false,
          cellRadio: null,
          id:null,
          Info:{},
          user:{},
          shop:{},
          shifuList:[],
          allmoney:0,
          srcList: [],
          housrcList:[]
        }
      },
      mounted() {
        this.id = this.$route.query.id
        this.pageName = this.$route.query.pageName
        this.getShifuList()
        this.getInfo()
      },
  
      methods: {
        //确认订单
        quren(id){
          this.$http.post('api/store/index/querenorderfuwu',{
            id:id,
          }).then(res =>{
            this.$message.success(res.data.msg)
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000);
          })
        },
        //上传
        handleAvatarSuccess(res, file) {
          //URL.createObjectURL(file.raw)
          this.imageUrl = this.$getimgsrc(res.data.url);
          this.$http.post('api/store/index/houshetaizhao',{
            id:this.Info.id,
            url:res.data.url
          }).then(res =>{
            this.$message.success('上传成功')
          }).catch(err =>{
            this.imageUrl= ''
          })
        },
        beforeAvatarUpload(file) {
          const isJPG = file.type === 'image/jpeg';
          const isLt2M = file.size / 1024 / 1024 < 2;

          if (!isJPG) {
            this.$message.error('上传图片只能是 JPG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 2MB!');
          }
          return isJPG && isLt2M;
        },
        //更换服务人员
        Selectchange(e,id){
            console.log(e,id);
            this.$http.post('api/store/index/replace',{
                id:id,
                shifu:e
            }).then(res =>{
                this.$message.success(res.data.msg)
            })
        },
        //获取服务人员
        async getShifuList(){
            await this.$http.post('api/store/index/shifu',{
                type:1
            }).then(res =>{
                // console.log(res);
                this.shifuList = res.data.data
            })
        },
        //获取详情
        getInfo(){
            this.$http.post('api/store/index/detail',{
                id:this.id
            }).then(res =>{
                this.Info = res.data.data
                this.user = res.data.data.user
                this.srcList.push(this.$getimgsrc(res.data.data.fur_image))
                if(res.data.data.furs_image){
                  console.log(this.$getimgsrc(res.data.data.furs_image));
                  this.housrcList.push(this.$getimgsrc(res.data.data.furs_image))
                }else{
                  
                }
                let arr = res.data.data.shop
                let money = 0
                for(let k in arr){
                  money += Number(arr[k].unit_price)*Number(arr[k].num)
                }
                //循环服务人员
                for(let i in arr){
                    if(arr[i].manystoreperson_id){
                     arr[i].manystoreperson_id = arr[i].manystoreperson_id.map(Number)
                    }else{
                        arr[i].manystoreperson_id = []
                    }
                }
                this.allmoney = money
                this.shop = arr
                console.log(res);
            })
        },
        // 返回上级
        returnOn() {
          this.$router.go(-1)
        },
        jumpAbout(pageName) {
          this.$router.push({
            name: 'AddReservation',
            params: {
              pageName,
            }
          })
        },
        // 修改
        jumpCaseReg() {
          this.$router.push({
            path: '/cash-reg'
          })
        },
        // 关闭
        closeOrder() {
          this.$confirm('关闭不可恢复, 确定要关闭单据吗?', '关闭确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() => {
            this.pageName = '已关闭'
            window.sessionStorage.setItem('pageName', this.pageName)
          }).catch(() => {
  
          });
        },
        // 打印
        logOrder() {
          this.$message({
            message: '打印成功',
            type: 'success'
          })
        },
        // 切换美容师
        changeTeacher(item) {
          this.teacherName = item
          this.serviceChangeShow = false
        },
        // 顾客已到店
        userArrive() {
          this.$message({
            message: '顾客已到店!!',
            type: 'success'
          })
          setTimeout(() => {
            this.pageName = '服务中'
            window.sessionStorage.setItem('pageName', this.pageName)
          }, 2000)
        },
        // 解除技师占用
        removeTakeUp() {
          this.$confirm('解除占用后技师可被预约且不可恢复，确认要解除该技师吗？', '解除占用', {
            confirmButtonText: '解除占用',
            cancelButtonText: '取消',
          }).then(() => {
  
          }).catch(() => {
  
          });
        },
        // 删除订单
        delOrder() {
          this.$confirm('删除不可恢复，确定要删除单据吗？', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() => {
            this.$router.go(-1)
          }).catch(() => {
  
          });
        },
        // 排班
        jumpScheduling() {
          this.$router.push({
            path: '/scheduling'
          })
        },
        // 付款
        jumpPaySettle() {
          this.$router.push({
            path: '/facepay-settle',
            query:{
              id:this.Info.id
            }
          })
        },
        // 关闭 dialog 弹窗
        handleClose() {
          this.markShow = false
          this.serviceChangeShow = false
        },
      }
    };
  
  </script>
  
  <style lang="less" scoped>
    .content {
      height: calc(100vh);
    }
  
    // 头部
    .el-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      color: #333;
      height: 60px !important;
      border-bottom: 1px solid #e9e9e9;
  
      .left {
        color: #46a6ff;
        font-size: 24px;
      }
  
      .center {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
  
        .text {
          margin-top: 5px;
          font-weight: normal;
          font-size: 12px;
        }
  
        .text1 {
          color: #ff2d2d;
        }
  
        .text2 {
          color: #fec53d;
        }
  
        .text3 {
          color: #82d588;
        }
      }
  
      .right {
        display: flex;
        justify-content: flex-end;
        color: #409eff;
  
        .r-box {
          padding: 0 10px;
        }
      }
    }
  
    /deep/ .main.el-main {
      background-color: #f9f9f9;
      padding: 15px !important;
      min-height: calc(100vh);
  
      .info {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
  
  
        .title {
          border-bottom: 1px solid #f2f2f2;
          font-weight: bold;
          font-size: 14px;
          line-height: 50px;
          color: #666;
          padding: 0 10px;
        }
  
        .list-box {
          .list {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            border-bottom: 1px solid #f2f2f2;
            padding: 0 10px;
  
            &:last-child {
              border-bottom: none;
            }
  
            .l-left {
              color: #666;
            }
  
            .l-right {
              color: #666;
  
              span {
                background-color: #ff9900;
                color: #fff;
                padding: 5px 15px;
                margin-left: 8px;
                border-radius: 5px;
                font-size: 12px;
              }
            }
          }
        }
  
        .service-box {
          .service-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #666;
            padding: 15px 10px;
            border-bottom: 1px solid #f2f2f2;
  
            &:last-child {
              border-bottom: none;
            }
  
            .s-l {
              width: 33%;
              line-height: 24px;
  
              .time {
                color: #999;
              }
            }
  
            .s-c {
              width: 33%;
              color: #f57272;
              font-weight: bold;
              text-align: center;
            }
  
            .s-r {
              width: 33%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
  
              .avatar {
                width: 35px;
                height: 35px;
  
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 100%;
                }
              }
  
              .name {
                margin-left: 10px;
              }
  
              .change {
                margin-left: 55px;
                font-size: 12px;
                color: #999;
  
                .span {
                  display: inline-block;
                  width: 80px;
                  line-height: 30px;
                  text-align: center;
  
                }
  
                .span1 {
                  border: 1px solid #ffb648;
                  color: #ffb648;
                  border-radius: 5px;
                }
  
                .span2 {
                  color: #19be6b;
                }
              }
            }
          }
        }
      }
  
    }
  
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #fff;
      padding: 10px 0;
      border-top: 2px solid #f2f2f2;
  
      .about {
        .el-button {
          width: 30%;
          min-width: 240px;
        }
      }
  
      .service {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
  
        .left {
          color: #666;
  
          span {
            margin: 0 2px;
            font-size: 12px;
          }
  
          i {
            font-style: normal;
            color: #f56c6c;
            font-weight: bold;
          }
        }
  
        .right {
          .el-button {
            min-width: 160px;
          }
        }
      }
    }
  
    /deep/ .el-dialog {
      min-width: 400px;
    }
  
    .dialog {
      /deep/ .el-dialog__body {
        height: 400px;
      }
  
      .alert {
        color: #ff9900;
        margin-bottom: 20px;
      }
  
      .cell-list {
        width: 100%;
  
        /deep/ .el-radio-group {
          width: 100%;
          position: relative;
  
          .el-radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #f2f2f2;
            padding: 10px;
            margin-bottom: 10px;
            margin-right: 0;
            position: relative;
            overflow: hidden;
  
            .disable {
              position: absolute;
              top: 0;
              right: -37px;
              font-size: 12px;
              transform: rotate(45deg);
              background-color: #f95e5a;
              color: #fff;
              width: 100px;
              line-height: 24px;
              text-align: center;
            }
  
            .title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
  
              img {
                width: 40px;
                height: 40px;
                margin-right: 10px;
              }
            }
  
            .el-radio__input {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
  
      }
    }
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    border: 1px dashed #d9d9d9;

    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  </style>
  